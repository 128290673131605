angular.module("componentes").controller("menuRightCtrl", [
	"$scope",
	"$rootScope",
	"clientService",
	"userAccessService",
	"$state",
	"httpService",
	"accountService",
	"translationService",
	"engagementScoreService",
	"permissionService",
	function (
		$scope,
		$rootScope,
		clientService,
		userAccessService,
		$state,
		httpService,
		accountService,
		translationService,
		engagementScoreService,
		permissionService
	) {
		var masterViewClientDataPermission;
		var masterEditClientDataPermission;
		var masterManageFeatureToggles;
		var hasMarketBackOfficePermission;

		var disposalLoadPermission;
		var isLowerGenerationPlan;
		var goalAccess;

		$scope.authentication = $rootScope.authentication;
		var labels = $rootScope.labels;

		$scope.TOSUrl = Vgr.util.getTermsAndConditionsUrl();
		$scope.privacyPolicyUrl = Vgr.util.getPrivacyPolicyUrl();

		$scope.totalItems = 20;
		$scope.clientSearch = "";
		$scope.userSearch = "";
		$scope.canChangeClient = true;
		$scope.canChangeLanguage = false;

		var onStateChangeSuccess = $rootScope.$on("$stateChangeSuccess", function (event, state) {
			selectMenuItemFromState(state.name);
		});
		var onChangeLoggedClientInfo = $rootScope.$on(Vgr.constants.evtChangeLoggedClientInfo, function () {
			loadLoginInformation();
		});
		var onChangedLoggedUserInfo = $rootScope.$on(Vgr.constants.evtChangeLoggedUserInfo, function () {
			loadLoginInformation();
		});
		var onChangeLoggedClient = $rootScope.$on(Vgr.constants.evtChangeLoggedClient, function () {
			loadInitialMenuValues();
		});

		this.$onInit = function () {
			$scope.ctrl.activeOption = "allUsers";

			loadInitialMenuValues();
		};

		this.$onDestroy = function () {
			// disable the listener
			onChangeLoggedClient();
			onChangedLoggedUserInfo();
			onChangeLoggedClientInfo();
			onStateChangeSuccess();

			// nullify the DOM-bound model
			$scope.domElement = null;
		};

		function loadInitialMenuValues() {
			loadLoginInformation();
			loadPermissions();
			setUserMenu();
			setShowMenuItemRecursively($scope.userMenu);
			showLanguageChange();

			$scope.isMaster = permissionService.isMaster();
		}

		function loadPermissions() {
			masterViewClientDataPermission = userAccessService.hasAccess(Vgr.systemPermissions.MasterViewClientData);
			masterEditClientDataPermission = userAccessService.hasAccess(Vgr.systemPermissions.MasterCreateEditClientData);
			masterManageFeatureToggles = userAccessService.hasAccess(Vgr.systemPermissions.MasterManageFeatureToggles);
			hasMarketBackOfficePermission = masterViewClientDataPermission
				? Vgr.enumerations.userAccess.Enabled
				: Vgr.enumerations.userAccess.Hidden;
			goalAccess = userAccessService.getAccess(
				Vgr.systemPermissions.GoalView,
				Vgr.constants.featureToggles.permission.goalModule,
				null
			);

			disposalLoadPermission =
				userAccessService.hasAccess(null, Vgr.constants.featureToggles.permission.disposalLoadScreen, null) &&
				permissionService.isMaster()
					? Vgr.enumerations.userAccess.Enabled
					: Vgr.enumerations.userAccess.Hidden;

			isLowerGenerationPlan = userAccessService.hasAccess(
				null,
				Vgr.constants.featureToggles.permission.lowerGenerationPlan
			);
		}

		function showLanguageChange() {
			if (userAccessService.hasAccess(null, null, Vgr.constants.featureToggles.release.changeLanguage)) {
				$scope.canChangeLanguage = true;
			} else {
				$scope.canChangeLanguage = false;
			}
		}

		$scope.toUser = function () {
			$rootScope.$broadcast(Vgr.constants.evtMenuLinkClick);
			$state.go("accountEdit");
		};

		function setUserMenu() {
			var unitMenu = {
				text: function () {
					return labels.UNIT;
				},
				level: 1,
				fixed: true,
				isFree: true,
				children: [
					{
						text: function () {
							return labels.GENERAL_DATA;
						},
						level: 2,
						states: ["clientEdit"],
						stateParams: {
							id: $scope.loggedClient ? $scope.loggedClient.id : null
						},
						isFree: true
					},
					{
						text: function () {
							return labels.DOCUMENTS;
						},
						level: 2,
						states: ["documentList", "documentCreate", "documentEdit"],
						getFeatureAccess: function () {
							return $scope.isSupplier ? Vgr.enumerations.userAccess.Enabled : Vgr.enumerations.userAccess.Hidden;
						},
						isFree: true
					},
					{
						text: function () {
							return labels.AUDITS_AND_OTHERS;
						},
						level: 2,
						states: ["transporterEdit"],
						stateParams: {
							id: $scope.loggedClient ? $scope.loggedClient.id : null
						},
						getFeatureAccess: function () {
							return $scope.isSupplier ? Vgr.enumerations.userAccess.Enabled : Vgr.enumerations.userAccess.Hidden;
						},
						isFree: true
					},
					{
						text: function () {
							return labels.AREAS;
						},
						level: 2,
						states: ["unitAreaList", "areaEdit"],
						getFeatureAccess: function () {
							return !$scope.isSupplier ? Vgr.enumerations.userAccess.Enabled : Vgr.enumerations.userAccess.Hidden;
						}
					},
					{
						text: function () {
							return labels.PROCESS_CONTROL;
						},
						level: 2,
						getFeatureAccess: function () {
							return !$scope.isSupplier ? Vgr.enumerations.userAccess.Enabled : Vgr.enumerations.userAccess.Hidden;
						},
						children: [
							{
								text: function () {
									return labels.RAW_MATERIALS;
								},
								level: 3,
								states: ["rawMaterialList", "rawMaterialEdit"]
							},
							{
								text: function () {
									return labels.PRODUCTS;
								},
								level: 3,
								states: ["productList", "productEdit"]
							},
							{
								text: function () {
									return labels.PROCESS_STEPS;
								},
								level: 3,
								states: ["processStepList", "processStepEdit"]
							},
							{
								text: function () {
									return labels.PROCESSES;
								},
								level: 3,
								states: ["processList", "processEdit"]
							}
						]
					},
					{
						text: function () {
							return labels.RESIDUES;
						},
						level: 2,
						getFeatureAccess: function () {
							return !$scope.isSupplier ? Vgr.enumerations.userAccess.Enabled : Vgr.enumerations.userAccess.Hidden;
						},
						children: [
							{
								text: function () {
									return labels.RECIPIENTS;
								},
								level: 3,
								states: ["recipientList", "recipientEdit"]
							},
							{
								text: function () {
									return labels.RESIDUES;
								},
								level: 3,
								states: ["residueList", "residueEdit"]
							},
							{
								text: function () {
									return labels.EMERGENCY_PHONES;
								},
								level: 3,
								states: ["emergencySheetPhones"],
								getFeatureAccess: function () {
									return isLowerGenerationPlan
										? Vgr.enumerations.userAccess.Hidden
										: Vgr.enumerations.userAccess.Enabled;
								}
							}
						]
					},
					{
						text: function () {
							return labels.PERMISSIONS;
						},
						level: 2,
						states: ["unitPermissionList"],
						isFree: true
					},
					{
						text: function () {
							return labels.GOALS;
						},
						level: 2,
						states: ["goalsList"],
						getFeatureAccess: function () {
							return goalAccess;
						}
					}
				]
			};

			var organizationMenu = {
				text: function () {
					return labels.ORGANIZATION;
				},
				level: 1,
				fixed: true,
				isFree: true,
				children: [
					{
						text: function () {
							return labels.GENERAL_DATA;
						},
						states: ["organizationEdit"],
						level: 2,
						isFree: true
					},
					{
						text: function () {
							return labels.UNITS;
						},
						states: ["unitList", "clientEdit"],
						level: 2,
						isFree: true
					},
					{
						text: function () {
							return labels.METADATA;
						},
						states: ["organizationAdditionalPropertiesList"],
						level: 2,
						isFree: true
					},
					{
						text: function () {
							return labels.ORGANIZATION_AREAS_INTERNAL;
						},
						level: 2,
						states: ["areaOrganizationList"],
						getFeatureAccess: function () {
							return masterViewClientDataPermission || masterEditClientDataPermission
								? Vgr.enumerations.userAccess.Enabled
								: Vgr.enumerations.userAccess.Hidden;
						}
					},
					{
						text: function () {
							return labels.RESIDUES;
						},
						level: 2,
						states: ["residueOrganizationList"],
						getFeatureAccess: function () {
							return isLowerGenerationPlan ? Vgr.enumerations.userAccess.Hidden : Vgr.enumerations.userAccess.Enabled;
						}
					},
					{
						text: function () {
							return labels.USERS;
						},
						states: ["userList"],
						level: 2,
						isFree: true
					},
					{
						text: function () {
							return labels.PERMISSIONS;
						},
						states: ["organizationPermissionList"],
						level: 2,
						isFree: true
					},
					{
						text: function () {
							return labels.SUPPLIER_MANAGEMENT;
						},
						level: 2,

						children: [
							{
								text: function () {
									return labels.DOCUMENT_TYPES;
								},
								level: 3,
								states: ["listDocumentType", "editDocumentType"]
							},
							{
								text: function () {
									return labels.ACTING_PROFILE;
								},
								level: 3,
								states: ["listSupplierProfile", "editSupplierProfile"]
							}
						]
					},
					{
						text: function () {
							return labels.DESTINATIONS_IMPORT;
						},
						states: ["disposalLoads"],
						level: 2,
						getFeatureAccess: function () {
							return disposalLoadPermission;
						}
					}
				]
			};

			var masterMenu = {
				text: function () {
					return labels.MASTER;
				},
				level: 1,
				fixed: true,
				isMaster: true,
				getFeatureAccess: function () {
					return masterViewClientDataPermission || masterEditClientDataPermission
						? Vgr.enumerations.userAccess.Enabled
						: Vgr.enumerations.userAccess.Hidden;
				},
				children: [
					{
						text: function () {
							return labels.ORGANIZATION_DATA;
						},
						level: 2,
						isMaster: true,
						states: ["clientOrgEdit"],
						getFeatureAccess: function () {
							return masterViewClientDataPermission || masterEditClientDataPermission
								? Vgr.enumerations.userAccess.Enabled
								: Vgr.enumerations.userAccess.Hidden;
						}
					},
					{
						text: function () {
							return labels.USER_QUERY;
						},
						level: 2,
						isMaster: true,
						states: ["masterUserList"],
						getFeatureAccess: function () {
							return masterViewClientDataPermission || masterEditClientDataPermission
								? Vgr.enumerations.userAccess.Enabled
								: Vgr.enumerations.userAccess.Hidden;
						}
					},
					{
						text: function () {
							return labels.FEATURE_TOGGLES;
						},
						level: 2,
						isMaster: true,
						states: ["featureToggleList"],
						getFeatureAccess: function () {
							return masterManageFeatureToggles
								? Vgr.enumerations.userAccess.Enabled
								: Vgr.enumerations.userAccess.Hidden;
						}
					},
					{
						text: function () {
							return labels.SUPPLIER_INDICATIONS;
						},
						level: 2,
						isMaster: true,
						getFeatureAccess: function () {
							return hasMarketBackOfficePermission;
						},
						children: [
							{
								text: function () {
									return labels.MARKET_RESIDUE_INFORMATION_GENERATION;
								},
								level: 3,
								isMaster: true,
								states: ["residueMarketBackOfficeSupplierListGeneration"],
								getFeatureAccess: function () {
									return hasMarketBackOfficePermission;
								}
							},
							{
								text: function () {
									return labels.MARKET_RESIDUE_SUPPLIER_BY_REGION;
								},
								level: 3,
								isMaster: true,
								states: ["residueMarketBackOfficeListSupplierByRegion"],
								getFeatureAccess: function () {
									return hasMarketBackOfficePermission;
								}
							}
						]
					}
				]
			};

			$scope.userMenu = {
				children: [unitMenu, organizationMenu, masterMenu]
			};
		}

		function setShowMenuItemRecursively(menuItem) {
			menuItem.hasChildren = $scope.hasChildren;

			if (menuItem.hasChildren()) {
				menuItem.children.forEach(function (child) {
					setShowMenuItemProperties(child);
				});
			}
		}

		function setShowMenuItemProperties(menuItem) {
			if (menuItem.getFeatureAccess) {
				setShowMenuItem(menuItem, menuItem.getFeatureAccess());
			} else {
				setShowMenuItem(menuItem, Vgr.enumerations.userAccess.Enabled);
			}
		}

		function setShowMenuItem(menuItem, featureAccess) {
			menuItem.showMenuItem = featureAccess != Vgr.enumerations.userAccess.Hidden;

			if (featureAccess == Vgr.enumerations.userAccess.Enabled) {
				if (menuItem.isFree || menuItem.isMaster) {
					menuItem.featureAccess = featureAccess;
				} else if (accountService.isMarketProfile()) {
					menuItem.featureAccess = Vgr.enumerations.userAccess.Disabled;
				} else {
					menuItem.featureAccess = featureAccess;
				}
			} else {
				menuItem.featureAccess = featureAccess;
			}

			if (menuItem.showMenuItem) {
				setShowMenuItemRecursively(menuItem);
			}
		}

		$scope.onClickMenuItem = function (menuItem, $event) {
			if ($event) {
				if (menuItem.clickMeasureKey) {
					engagementScoreService.track(menuItem.clickMeasureKey);
				}
				$event.stopPropagation();
				if (menuItem.states) {
					$rootScope.$broadcast(Vgr.constants.evtMenuLinkClick);
					$state.go(menuItem.states[0], menuItem.stateParams);
				}
			} else {
				$scope.toggleAllRelatedToggledItems($scope.userMenu, menuItem);
			}

			$scope.untoggleAllUnrelatedToggledItems($scope.userMenu, menuItem);
			$scope.unsetAllItemsActive($scope.userMenu, menuItem);
			menuItem.toggled = !menuItem.toggled;
			if (menuItem.children) {
				menuItem.active = !menuItem.active;
			} else {
				menuItem.active = true;
			}
		};

		$scope.radioOptions = [
			{
				label: $rootScope.labels.CHANGE_USER_ALL,
				value: "allUsers"
			},
			{
				label: $rootScope.labels.CHANGE_USER_ACTIVE,
				value: "activeUsers"
			},
			{
				label: $rootScope.labels.CHANGE_USER_INACTIVE,
				value: "inactiveUsers"
			}
		];

		$scope.onClickLogout = function () {
			accountService.logoutConditional().then(function (response) {
				if (response) {
					accountService.goToLogin();
				} else {
					accountService.goHome(true);
				}
			});
		};

		function loadLoginInformation() {
			$scope.isSupplier = clientService.isSupplier();
			$scope.loggedClient = accountService.getCurrentClient();
			$scope.loggedUser = accountService.getLoggedAccount();

			if (!$scope.loggedClient) {
				$scope.nameToDisplay = null;
			} else if (String($scope.loggedClient.code).length > 10) {
				$scope.nameToDisplay = $scope.loggedClient.name;
			} else {
				$scope.nameToDisplay = $scope.loggedClient.code + " - " + $scope.loggedClient.name;
			}
		}

		$scope.unitList = [];
		$scope.filterUnitModel = {
			query: "",
			onlyPayers: true,
			onlyActives: true
		};
		var loadedAllUnits = false;
		var skip = 0;
		const unitsPerPage = 100;
		var loadingClients = false;

		function resetOrganizationUnitListInSelectUnitComponent() {
			$scope.unitList = [];
			loadedAllUnits = false;
			skip = 0;
		}

		function resetSelectUnitComponentFilters() {
			$scope.filterUnitModel.query = "";
		}

		$scope.firstClientLoad = function () {
			if (loadingClients) {
				return;
			}

			listClients();
		};

		$scope.onChangeSelectUnitFilter = function () {
			resetOrganizationUnitListInSelectUnitComponent();

			listClients();
		};

		$scope.onClientsScrollEnds = function () {
			if (loadingClients) {
				return;
			}

			listClients();
		};

		function listClients() {
			if (loadedAllUnits) {
				return;
			}

			loadingClients = true;

			var dto = {
				query: $scope.filterUnitModel.query,
				skip: skip,
				take: unitsPerPage
			};

			if ($scope.isMaster) {
				dto.active = $scope.filterUnitModel.onlyActives;
				dto.payers = $scope.filterUnitModel.onlyPayers;
			}

			httpService.getListFromServiceCore(clientService.listOrganizationUnitsToLogin, dto).then(
				function (response) {
					var list = response.list;

					for (var index = 0; index < list.length; index++) {
						list[index].nameToList = buildClientNameForSelectUnitComponent(list[index]);
					}

					$scope.unitList = $scope.unitList.concat(list);
					skip++;
					loadingClients = false;

					if (list.length < unitsPerPage) {
						loadedAllUnits = true;
					}
				},
				function () {
					loadingClients = false;
				}
			);
		}

		function buildClientNameForSelectUnitComponent(client) {
			var description = "";

			if ($scope.isMaster) {
				description += client.organization.id + " - " + client.organization.name + " // ";

				if (client.id == client.code) {
					description += client.id + " - " + client.name;
				} else {
					description += client.id + " - " + client.code + " - " + client.name;
				}

				if (client.city) {
					description += " // " + Vgr.util.capitalizeText(client.city.name) + " - " + client.city.state.abbreviation;
				}
			} else {
				description += client.code + " - " + client.name;

				if (client.city) {
					description += " // " + Vgr.util.capitalizeText(client.city.name) + " - " + client.city.state.abbreviation;
				}
			}

			return description;
		}

		$scope.changeLanguage = function (newLanguage) {
			if (newLanguage != translationService.getLanguage()) {
				var languageSet = translationService.setLanguage(newLanguage);
				if (languageSet) {
					accountService.setUserLanguage(newLanguage).then(function () {
						accountService.removeEntitiesCache();
						window.location.reload();
					});
				}
			}
		};

		$scope.getSelectedLanguageClass = function (language) {
			if ($scope.showLanguages) {
				if (language == translationService.getLanguage()) {
					return "language-option-visible selected";
				}
				return "language-option-visible";
			}
			return "hide";
		};

		$scope.getLanguageSelectorClass = function () {
			if ($scope.showLanguages) {
				return "off-focus";
			}
			return "";
		};

		$scope.openLanguageOptions = function () {
			$scope.showLanguages = !$scope.showLanguages;
		};

		function selectMenuItemFromState(stateName) {
			var currentItem = $scope.findItemByState($scope.userMenu, stateName);
			if (currentItem) {
				$scope.onClickMenuItem(currentItem);
			} else {
				$scope.untoggleAllUnrelatedToggledItems($scope.userMenu, null);
				$scope.unsetAllItemsActive($scope.userMenu, null);
			}
		}

		$scope.$watch("userMenu", function (newVal) {
			if (newVal) {
				selectMenuItemFromState($state.current.name);
			}
		});

		$scope.getParent = function (menuItem, refItem) {
			var parent = null;
			menuItem.hasChildren = $scope.hasChildren;

			if (menuItem.hasChildren()) {
				var child = {};
				for (var i = 0; i < menuItem.children.length; ++i) {
					child = menuItem.children[i];
					child.hasChildren = $scope.hasChildren;

					if ($scope.isEqualToItem(child, refItem)) {
						parent = menuItem;
					} else if (child.hasChildren()) {
						parent = $scope.getParent(child, refItem);
					}

					if (parent) {
						return parent;
					}
				}
			}
			return parent;
		};

		$scope.hasChildren = function hasChildren() {
			if (Array.isArray(this.children)) {
				return this.children.length > 0;
			}
			return false;
		};

		$scope.unsetAllItemsActive = function (menuItem, butItem) {
			if (menuItem.children) {
				for (var i = 0; i < menuItem.children.length; ++i) {
					$scope.unsetAllItemsActive(menuItem.children[i], butItem);
				}
			}
			if (!butItem || butItem.text !== menuItem.text) {
				menuItem.active = false;
			}
		};

		$scope.untoggleAllUnrelatedToggledItems = function (menuItem, refItem) {
			menuItem.hasChildren = $scope.hasChildren;

			if (menuItem.hasChildren()) {
				for (var i = 0; i < menuItem.children.length; ++i) {
					if (menuItem.children[i].children) {
						$scope.untoggleAllUnrelatedToggledItems(menuItem.children[i], refItem);
					}
					if (!$scope.hasChildrenOrEqual(menuItem.children[i], refItem)) {
						menuItem.children[i].toggled = false;
					}
				}
			}
		};

		$scope.toggleAllRelatedToggledItems = function (menuItem, refItem) {
			for (var i = 0; i < menuItem.children.length; ++i) {
				menuItem.children[i].toggled = false;
				if (menuItem.children[i].children) {
					$scope.toggleAllRelatedToggledItems(menuItem.children[i], refItem);
				}
				if ($scope.hasChildrenOrEqual(menuItem.children[i], refItem)) {
					menuItem.children[i].toggled = true;
				}
			}
		};

		$scope.isEqualToItem = function (menuItem, refItem) {
			return refItem && menuItem.text === refItem.text;
		};

		$scope.hasState = function (menuItem, state) {
			if (!menuItem.states) {
				return false;
			}

			for (var i = 0; i < menuItem.states.length; ++i) {
				if (menuItem.states[i] === state) {
					return true;
				}
			}
			return false;
		};

		$scope.findItemByState = function (menuItem, state) {
			var item = null;
			if (!menuItem) {
				return;
			}
			for (var i = 0; i < menuItem.children.length; ++i) {
				if ($scope.hasState(menuItem.children[i], state)) {
					item = menuItem.children[i];
				} else if (menuItem.children[i].children) {
					item = $scope.findItemByState(menuItem.children[i], state);
				}
				if (item) {
					return item;
				}
			}
			return item;
		};

		$scope.hasChildrenOrEqual = function (menuItem, refItem) {
			var hasChildren = false;
			if ($scope.isEqualToItem(menuItem, refItem)) {
				hasChildren = true;
			} else if (menuItem.children) {
				for (var i = 0; i < menuItem.children.length; ++i) {
					if ($scope.isEqualToItem(menuItem.children[i], refItem)) {
						hasChildren = true;
					} else if (menuItem.children[i].children) {
						hasChildren = $scope.hasChildrenOrEqual(menuItem.children[i], refItem);
					}

					if (hasChildren) {
						return true;
					}
				}
			}
			return hasChildren;
		};

		window.addEventListener("click", function (e) {
			var navBar = document.getElementById("navBar");
			var toggleButton = document.getElementById("toggleButton");
			if (navBar && toggleButton) {
				if (
					!document.getElementById("navBar").contains(e.target) &&
					!document.getElementById("toggleButton").contains(e.target)
				) {
					$scope.$apply();
				}
			}
		});

		$scope.onClickChangeClient = function (client) {
			$rootScope.$broadcast(Vgr.constants.evtCloseUserNavBar);
			accountService.changeClient(client.id).then(function () {
				accountService.goHome(true);
			});
			resetOrganizationUnitListInSelectUnitComponent();
			resetSelectUnitComponentFilters();
		};

		$scope.close = function () {
			$rootScope.$broadcast(Vgr.constants.evtCloseUserNavBar);
		};

		$scope.showAvatar = function () {
			return window.innerHeight >= 420;
		};
	}
]);
